@import-normalize;
@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@layer components {
  .loading-spinner {
    display: flex;
    align-items: center;
  }

  .dot {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 50%;
    background-color: #000; /* Change this color as needed */
  }

  .typing-text {
    font-size: 14px;
    color: #000; /* Change this color as needed */
  }
}
  /* SideBar */
  .sidebar {
    @apply flex flex-col gap-y-4 h-screen bg-slate-50 p-2 pt-4 relative duration-300;
  }

  .sidebar__app-bar {
    @apply flex justify-between items-center;
  }

  .sidebar__app-title {
    @apply text-slate-800 font-medium text-xl duration-300;
  }

  .sidebar__btn-close {
    @apply cursor-pointer duration-300 text-black justify-end;
  }

  .sidebar__btn-icon {
    @apply w-8 h-8;
  }

  .sidebar__app-logo {
    @apply cursor-pointer duration-300 text-white;
  }

  /* SideBar Nav */
/* Add these styles to your CSS file */
.bg-space {
  background-image: url("assets/baba.png"); /* Replace 'path/to/your/image.jpg' with your image path */
  background-size: cover; /* Ensures the background image covers the entire element */
  background-repeat: no-repeat; /* Prevents the background image from repeating */
  /* You can also add other background properties like position and attachment if needed */
}


.bg-dark-blue {
  background-color: #545454; /* Dark blue for the app bar */
}

.bg-blue-500 {
  background-color: #545454; /* Blue color for buttons */
}

.text-white {
  color: #000000; /* White text color */
}

.sidebar__btn-icon {
  font-size: 24px;
}

.nav__icons {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.nav__item {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 8px;
  margin-bottom: 8px;
  transition: background-color 0.3s;
}

.nav__item:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.nav__middle {
  flex-grow: 1; /* Ensures it takes up available space */
}

.nav__bottom {
  margin-top: auto; /* Pushes the bottom section to the bottom */
}

.mr-2 {
  margin-right: 0.5rem; /* Add space between icon and text */
}


  /* CHATVIEW */
  .chatview {
    @apply flex flex-col h-screen duration-300 overflow-hidden relative;
     /* Replace with your image path */
    /* background-image: url("assets/bi.jpg"); */
    background-size: cover; /* Ensure the background image covers the entire element */
    background-repeat: no-repeat; /* Prevent the background image from repeating */
    background-color: rgba(255, 255, 255, 0.5); /* Adjust the opacity (last value) as needed */
  }
  
  
  
  .chatview__chatarea {
    @apply flex-grow w-full overflow-y-scroll flex flex-col shadow-md;
  }

  .form {
    @apply flex justify-between flex-col items-stretch sm:flex-row m-2 bottom-0 left-0 right-0
    text-black dark:text-white duration-300;
  }

  .dropdown {
    @apply dark:bg-light-grey h-16 px-3 rounded-xl bg-light-white
     hover:bg-slate-200 hover:dark:bg-dark-grey border-2 border-slate-400
    dark:border-slate-500 focus:ring-offset-indigo-200 text-black
     dark:text-white transition  ease-in-out duration-300 text-center 
     text-base font-semibold shadow-md  focus:outline-none focus:ring-2
      focus:ring-offset-2;
  }

  .message__img {
    @apply rounded-lg shadow-lg bg-cover bg-gray-400 w-[512px] duration-300 ease-out;
  }

  /* Additional styles from your original code */
  .chatview__textarea-message {
    @apply bg-light-white ml-0 sm:ml-2 dark:bg-light-grey sm:w-full grow p-2 w-full
    border-2 border-slate-400 dark:border-slate-500 overflow-y-hidden mx-2 focus:outline-none
    resize-y rounded-xl;
  }

  .chatview__btn-send {
    @apply h-16 w-16 px-4 mx-2 rounded-full cursor-pointer hover:bg-slate-200 hover:dark:bg-dark-grey border-2 border-slate-400
    dark:border-slate-500 focus:ring-offset-indigo-200 text-black dark:text-white transition 
    ease-in-out duration-300 text-center text-base font-semibold shadow-md
    flex items-center justify-center
    focus:outline-none focus:ring-0 focus:ring-offset-0;
  }

  .chatview__btn-send:disabled {
    @apply opacity-40 transition ease-in duration-300 text-center text-base font-semibold shadow-md;
  }

/* ChatMessage */
.message {
  @apply py-5 gap-2 flex items-start justify-end;
  background-color: rgba(188, 6, 6, 0); /* Adjust the RGB values and opacity (0.8) as needed */
  color: #c44d4d; /* Change to your desired text color */
}


  .message__wrapper {
    @apply flex-grow w-screen flex-wrap overflow-hidden;
  }

  .message__markdown {
    @apply text-sm text-slate-700 whitespace-pre-wrap dark:text-slate-200;
    color: #131313; /* Change this color to your desired text color */
  }
  .message__createdAt {
    font-size: 10px;
    @apply font-thin italic text-slate-600 dark:text-slate-300;
  }
  .message__pic {
    @apply h-8 w-8 bg-slate-200 mx-2 rounded-full flex items-center justify-center text-xl;
  }

  .message__thinking {
    @apply animate-pulse duration-1000 h-12 w-12 dark:text-white text-black text-xl;
  }

  /* Signin Page */

  .signin {
    @apply flex flex-col justify-center items-center h-screen w-screen overflow-hidden bg-dark-grey;
  }

  .signin__container {
    @apply border-4 rounded-2xl h-72 w-72 p-5 flex flex-col justify-center items-center;
  }

  .signin__btn {
    @apply h-12 px-4 rounded-xl bg-slate-100 hover:bg-slate-300 border
     border-light-grey focus:ring-offset-indigo-200 text-black transition 
    ease-in-out duration-300 text-center text-base font-semibold shadow-md 
    focus:outline-none focus:ring-2 focus:ring-offset-2;
  }

  .signin__tos {
    @apply text-white text-center font-bold p-2;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  


::-webkit-scrollbar {
  @apply bg-transparent w-1;
}

::-webkit-scrollbar-thumb {
  @apply bg-light-grey dark:bg-dark-grey rounded-xl;
}
.chatview__typing-indicator {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  font-size: 14px;
  color: #4CAF50;
  margin-left: 85px; /* Ajustez cette valeur selon vos besoins */
}

.typing-text {
  font-size: 14px;
}

.typing-text span {
  display: inline-block;
  animation: jump 1.4s infinite;
  font-size: 40px; /* Ajustez la taille des points */
  vertical-align: top; /* Positionne les points en haut */
  line-height: 0.8; /* Ajustez cette valeur pour l'espacement vertical */
  opacity: 0.2; /* Démarrez avec une faible opacité */
  transform: translateY(0); /* Assurez-vous qu'il commence au bon endroit */
}

.typing-text span:nth-child(1) {
  animation-delay: 0.2s;
}

.typing-text span:nth-child(2) {
  animation-delay: 0.4s;
}

.typing-text span:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes jump {
  0% {
    opacity: 0.2;
    transform: translateY(0); /* Position de départ */
  }
  50% {
    opacity: 1;
    transform: translateY(-10px); /* Sauter vers le haut */
  }
  100% {
    opacity: 0.2;
    transform: translateY(0); /* Retour à la position de départ */
  }
}
/* styles.css */

/* styles.css */

.message__feedback {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}

.message__feedback-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 4px; /* Adjust padding for button size */
}

.message__feedback-button:hover {
  transform: scale(1.1);
}

.message__feedback-icon {
  stroke: #000; /* Black stroke */
  fill: inherit; /* Transparent fill */
  transition: fill 0.2s; /* Smooth transition for fill color change */
}
.message__feedback-button.clicked .message__feedback-icon {
  fill: #4caf50; /* Green color for like */
}

.message__feedback-button.disliked .message__feedback-icon {
  fill: #f44336; /* Red color for dislike */
}
/* New styles for voice read button */
.message__feedback-button.voice-read .message__feedback-icon {
  fill: #1c1d1d; /* Blue color for voice read button */
}

.message__feedback-button.voice-read.clicked .message__feedback-icon {
  fill: #ff8c00; /* Orange color when voice read button is clicked */
}
.suggestions-dropdown {
  position: absolute;
  top: -170px; /* Adjust based on the height of your suggestions list */
  right: 0;
  width: 300px;
  max-height: 200px; /* Adjust based on the desired max height */
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.suggestion {
  padding: 10px;
  cursor: pointer;
}

.suggestion:hover {
  background-color: #f0f0f0;
}
.chatview__btn-send {
  transition: background-color 0.3s;
}

.chatview__btn-send.active {
  background-color: #ffc107; /* Change to the color you want for the active state */
}

.chatview__btn-send.recording {
  background-color: #dc3545; /* Color for recording state */
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-logo {
  animation: fadeInUp 0.5s ease-out;
}
/* styles.css ou un fichier CSS de votre choix */
/* styles.css ou un fichier CSS de votre choix */

/* Styles généraux pour les modales */
/* styles.css */

/* Modal content */
.modal-content {
  background-color: #ffffff; /* Change this to your desired background color */
  color: #fff; /* Text color for modal content */
  padding: 20px;
  border-radius: 8px;
}

/* Overlay background */
.modal-overlay {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
}
.white{
  color: #ffffff; /* Change color to your desired color */
  /* Add any other styles you want for the title */
}
